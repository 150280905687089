h2,
h1 {
  color: #666;
  font-size: 50px;
}

img {
  border: 0px;
}

.backtoTop:hover {
  color: #FA944B;
}

/* Slick Slider Setting */
.slick-next:before,
.slick-prev:before {
  content: "" !important;
}

.next-slick-arrow,
.prev-slick-arrow {
  color: #000000;
  font-size: 48px;
}

.next-slick-arrow,
.prev-slick-arrow {
  color: #000000;
  font-size: 48px;
}

.slick-prev {
  left: -52px !important;
}

.slick-arrow {
  color: black;
}

/* Hero Slider */
.hero .slider {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

/* header slider */
.hero .slider img {

  height: 60vh;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hero .headling {
  height: 13vh;
  background-color: #f8f9fa;
  margin-bottom: 20px;
}

.hero .headling p {
  color: #666;
  font-size: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.about {
  height: 40vh;
  margin-top: 50px;
  margin-bottom: 50px;
  background: #eeeeee;
  padding: 50px 0;
}


.about p {
  color: #666;
}

.about .aboutCont {
  padding-top: 35px;
  padding-bottom: 35px;

}

#products .card {
  margin: 30px 30px 30px 30px;
  width: 300px;
  height: auto;
  border: 0px;
  content: fit-content;
}


#products .card .card-image:hover {
  box-shadow: 10px 12px 11px -5px rgba(0, 0, 0, 0.31);
  border-radius: 40px;
  transition: all 0.3s ease;
}

#products .card:hover {
  color: #FA944B;
}


.productDesc {
  border-right: #000000 1px solid;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
}

.iconContainer img {
  width: 100px;
}

.iconContainer label {
  font-size: 12px;
}

.miniSlider img {
  width: auto;
  height: 400px;
  object-fit: cover;
}



.thumbnailImg img {
  border: #eeeeee 1px solid;
  background-color: #eeeeee;
  height: 150px;
  width: 150px;
  object-fit: cover;

}

.divColor {
  margin-top: -40px;
  background: #eeeeee;
  width: 100%;
  height: 150px;

}

.miniSlider .slick-dots {
  padding-top: -555px;
}

.miniSlider .sliderImg img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.productDetail .slider {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.productDetail .slider img {
  height: auto;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


li {
  font-size: 17px;
}

/* Related Products */
.Rproduct {
  height: 60vh;

}

.Rproduct h1 {
  padding-top: 50px;

}

.Rproduct .slider {
  padding-top: 30px;

}

.Rproduct .slider img {
  height: 30vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}



@media screen and (max-width: 800px) {

  /* Hero Slider */
  .hero .slider {
    height: 20vh;
    margin-left: 10px;
  }

  .hero .slider img {
    height: 20vh;
    width: 100%;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.hide {
  display: none;
}

.relatedProducts a {
  border: none;
  text-decoration: none;
  color: #333;
}

.relatedProducts a:focus,
.relatedProducts a::after,
.relatedProducts a:active,
.relatedProducts a::before,
.relatedProducts a:active {
  border:none;
}

.relatedProducts a:hover {
  color: #FA944B;
  text-decoration: none;
}