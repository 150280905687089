.Privacy{
  font-size: 22px; 
}
ul {
    list-style: none;
    display: inline;
    margin-left: 0 !important;
    padding: 0;
  }
  
  ul.social-network li {
    display: inline;
    margin: 0 5px;
  }

  
  ol li {
    color: #f9944b;
    margin: 0 5px;
  }
  .Privacy p{
    text-align: justify;
  }
  .Privacy span{
    color: #f9944b;
  }
  
  .Privacy a{
    color: #f9944b;
  }