/*FOOTER*/

footer {
    background: #eeeeee;
    margin-top:100px;
  }
  
footer h4 {
    color: #FA944B;
    font-weight: 400;
  }
  .name{
    color: #FA944B;
    font-weight: 400;
  }

footer a {
    text-decoration: none;
    color: #333;
  }
  
footer a:hover {
    color: #FA944B;
    text-decoration: none;
  }
  .copy img{
    float: right; line-height: 70px; vertical-align: top
  }
  .foot{
    float: right;
  }
  

.footer-middle {
    padding-top: 2em;
  }
  
  
  /*SOCİAL İCONS*/
  
  /* footer social icons */
  
  ul.social-network {
    list-style: none;
    display: inline;
    margin-left: 0 !important;
    padding: 0;
  }
  
  ul.social-network li {
    display: inline;
    margin: 0 5px;
  }
  