/* Maxmimum screen size: 1000px */
@media screen and (max-width: 1000px) {
    p {
        font-size: 15px;
    }

    /* Hero Slider */
    .slider {
        padding-left: 70px;
        padding-right: 50px;
    }

    .slider img {
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #products .card {
        margin: 30px 30px 30px 30px;
        width: 300px;
        height: 300px;
        border: 0px;
    }


    #products .card .img-fluid {
        width: 100%;
        height: 200px;
        object-fit: contain;
    }

    li {
        font-size: 15px;
    }

    label {
        font-size: 15px;
    }

    p {
        font-size: 15px;
    }

}


/* Maxmimum screen size: 800px */
@media screen and (max-width: 800px) {

    html,
    body {
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;

    }

    /* Hero Slider */
    .slider {
        padding-left: 70px;
        padding-right: 50px;
    }

    .slider img {
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #products .card {
        margin: 30px 30px 30px 30px;
        width: 300px;
        height: 300px;
        border: 0px;
    }


    #products .card .img-fluid {
        width: 100%;
        height: 200px;
        object-fit: contain;
    }

    .productDesc {
        border-right: 0px;
    }

    .RproductDesc {
        padding-top: 20px;
    }

    .divColor {
        display: none;
    }



}

/* Maxmimum screen size: 600px */
@media screen and (max-width: 600px) {
    h1 {
        font-size: 19px;
    }

    h2 {
        font-size: 17px;
    }

    h3 {
        font-size: 15px;
    }

    p,
    span {
        font-size: 15px;
    }

    html,
    body {
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        overflow-x: hidden;

    }

    .hero .headling p {
        color: #666;
        font-size: 15px;

    }


    .slider img {
        height: 20vh;
        width: 100%;
        align-items: center;
        display: center;
        margin-left: auto;
        margin-right: auto;
    }

    .about {
        height: auto;
        text-align: justify;
        text-justify: inter-word;

        background-color: #eeeeee;
    }

    #products {
        margin-left: 20px;

    }

    #products .card {
        width: 200px;
        height: 300px;
        border: 0px;
    }


    #products .card img {
        width: 100%;
        height: 200px;
        object-fit: contain;
    }


    .productDesc {
        border-right: 0px;
    }

    .divColor {
        display: none;
    }

    .Rproduct {
        padding-top: 0px;
        margin-bottom: -100px;
    }

    .Rproduct .sliders img {
        height: auto;
        width: auto;
    }
}

/* Maxmimum screen size: 400px */
@media screen and (max-width: 400px) {
    .next-slick-arrow {
        display: none;
    }

}